body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(197, 195, 195, 0.5);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.active{
 color: rgb(70, 86, 235);
 background-color: rgba(70, 86, 235, 0.2);
}
.navb-link{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.flex-column{
  display: flex;
  flex-direction: column;
}
.flex-row{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.j-space{
  justify-content: space-between;
}
.j-center{
  justify-content: center;
}
.margin-0{
  margin: 0;
}

.dis1{
  background-color: rgb(233, 175, 15);
}
.font{
  font-family: Arial, Helvetica, sans-serif;
}
.grid-center{
  display: grid;
  place-items: center;
}
.text-center{
  text-align: center;
}
.link{
  text-decoration: none;
}
.primary{
 color: rgb(34, 34, 34);
}
.secondary{
  background-color: rgb(68, 94, 241);
}
.bold{
  font-weight: bold;
}
.navi{
  width: 100%;
  color: rgb(238, 235, 235);
  text-align: justify;
}
.my-scroll-div {
  height: 100vh;
  overflow-y: scroll;
  background-color: rgb(18, 18, 43);
  color: #ffffff;
}

.my-scroll-div::-webkit-scrollbar {
  width: 5px;
}

.my-scroll-div::-webkit-scrollbar-track {
  background: #2e2e2e;
  border-radius: 10px;
}

.my-scroll-div::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(243, 243, 250, 0.4), rgba(243, 243, 250, 0.4));
  border-radius: 10px;
}

.my-scroll-div::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(243, 243, 250, 0.4), rgba(243, 243, 250, 0.4));
}
.padding-0{
  padding: 0;
}
.topBar{
  max-height: 10vh;
  position: relative;
}
.padding-sm{
  padding: 5px;
}
.inputs{
  border: #cecdcd solid 1px;
  max-height: 80%;
}
.blur-purple{
  background-color: rgba(228, 63, 228, 0.2);
}
.blur-green{
  background-color: rgba(86, 230, 86, 0.2);
}
.blur-pink{
  background-color: rgba(247, 171, 184, 0.2);
}
.blur-blue{
  background-color: rgba(81, 104, 235, 0.2);
}
.blur-yellow{
  background-color: rgba(235, 235, 59, 0.2);
}
.blur-white{
  background-color: rgba(94, 91, 91, 0.2);
}
.t-elem{
  background-image: linear-gradient(rgb(45, 196, 201) 50%, rgb(71, 4, 71) 50%);
}
.table-div::-webkit-scrollbar {
  height: 5px;
}

.table-div::-webkit-scrollbar-track {
  background: #2e2e2e;
  border-radius: 10px;
}

.table-div::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, rgba(243, 243, 250, 0.4), rgba(243, 243, 250, 0.4));
  border-radius: 10px;
}

.table-div::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, rgba(243, 243, 250, 0.4), rgba(243, 243, 250, 0.4));
}
.margin-tp{
  margin-top: 50px;
}
.input-max{
  max-height: 8vh;
}
.logo {
  font-size: 50px;
  font-weight: bold;
  color: #f09696;
  letter-spacing: 5px;
  position: relative;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.logo::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 4px;
  background-color: #3498db;
  bottom: -10px;
  left: 0;
  transition: width 0.4s ease-in-out;
}

.logo:hover::before {
  width: 100%;
}

.logo:hover {
  color: #3498db;
  transform: scale(1.1);
}
.hide{
  display: none;
}
.modal-backdrop.show {
  opacity: 0.5;
}